import {
    LazyMotion,
    AnimatePresence,
    domAnimation,
    m
} from 'framer-motion';
import React from 'react';
import VariantsEnum from '@/enums/VariantsEnum';
import {fade, fadeScale} from '@/helpers/animation/fade';
import {useGlobalState} from '@/store/index';
import {removeMessage} from '@/store/message/actions';
import Button from '../form-control/button';
import styles from './alert.module.css';

interface IAlert {
    className?: string
    variant?: VariantsEnum
    timeout?: number
    isOpen?: boolean
}

const Alert: React.FC<React.PropsWithChildren<IAlert>> = ({
    children,
    variant,
    className = '',
    timeout,
    isOpen = false,
}) => {
    const [isOpened, setOpened] = React.useState<boolean>(isOpen);
    const timer = React.useRef<NodeJS.Timeout>();
    const [, dispatch] = useGlobalState();
    const remove = () => {
        setOpened(false);
        dispatch(removeMessage());
    };
    const handleClick = () => {
        remove();
    };

    React.useEffect(() => {
        if (timeout) {
            timer.current = setTimeout(() => {
                remove();
            }, timeout * 1000);
        }

        return () => {
            if (timer.current) {
                clearTimeout(timer.current);
            }
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AnimatePresence>
            {isOpened && (
                <LazyMotion strict features={domAnimation}>
                    <m.div
                        initial="initial"
                        animate="enter"
                        exit="exit"
                        variants={fade}
                        className={styles.backdrop}
                    />
                    <m.div
                        initial="initial"
                        animate="enter"
                        exit="exit"
                        variants={fadeScale}
                        className={`${styles.alert} ${variant ? styles[variant] : ''} ${className}`}
                        data-test="component-alert"
                    >
                        {timeout && (
                            <m.div
                                initial={{width: 0}}
                                animate={{width: '100%', transition: {duration: (timeout)}}}
                                className={styles.progress}
                                data-chromatic="ignore"
                            />
                        )}
                        <Button aria-label="Zavřít" className={styles.button} onClick={handleClick}>&times;</Button>
                        {children}
                    </m.div>
                </LazyMotion>
            )}
        </AnimatePresence>
    );
};

export default Alert;
