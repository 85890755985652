import React from 'react';
import {useGlobalState} from '@/store/index';
import setMenu from '@/store/menu/actions';
import styles from './content.module.css';

interface IContent {
    className?: string
}

const Content: React.FC<React.PropsWithChildren<IContent>> = ({children, className = ''}) => {
    const [, dispatch] = useGlobalState();

    React.useEffect(() => {
        dispatch(setMenu({menu: {isOpen: false}}));
    }, [dispatch]);

    return (
        <section className={`${styles.section} ${className}`} data-test="component-content">
            {children}
        </section>
    );
};

export default Content;
